import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Components from './pages/Components';

function App() {
    return (
        <div>
            <nav className="bg-blue-500 p-4">
                <ul className="flex space-x-4">
                    <li>
                        <Link to="/" className="text-white hover:underline">Home</Link>
                    </li>
                    <li>
                        <Link to="/projects" className="text-white hover:underline">Projects</Link>
                    </li>
                    <li>
                        <Link to="/components" className="text-white hover:underline">Components</Link>
                    </li>
                </ul>
            </nav>
            <main className="p-4">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/components" element={<Components />} />
                </Routes>
            </main>
        </div>
    );
}

export default App;
