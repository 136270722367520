import React from 'react';

function Home() {
    return (
        <div>
            <h1 className="text-4xl font-bold">Welcome to the Home Page</h1>
            <p className="mt-4">This is the basic home page of your application.</p>
        </div>
    );
}

export default Home;
