import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Projects() {
    const [projects, setProjects] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [userEmail, setUserEmail] = useState(''); // Assuming you get user email from somewhere

    useEffect(() => {
        const fetchProjects = async () => {
            const response = await axios.get('https://localhost/api/projects');
            setProjects(response.data);
        };

        fetchProjects();
    }, []);

    const handleCreateProject = async () => {
        try {
            await axios.post('https://localhost/api/projects', { title, description, user_email: userEmail });
            alert('Project created');
        } catch (error) {
            alert('Error creating project');
        }
    };

    const handleLikeProject = async (projectId) => {
        try {
            await axios.post(`https://localhost/api/projects/${projectId}/like`);
            const updatedProjects = projects.map(project =>
                project.id === projectId ? { ...project, likes: project.likes + 1 } : project
            );
            setProjects(updatedProjects);
            alert('Project liked');
        } catch (error) {
            alert('Error liking project');
        }
    };

    return (
        <div>
            <h2>Projects</h2>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
            <button onClick={handleCreateProject}>Create Project</button>
            <ul>
                {projects.map((project) => (
                    <li key={project.id}>
                        {project.title} - Likes: {project.likes}
                        <button onClick={() => handleLikeProject(project.id)}>Like</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Projects;
