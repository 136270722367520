import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Components() {
    const [components, setComponents] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchComponents = async () => {
            const response = await axios.get('https://localhost/api/components');
            setComponents(response.data);
        };

        fetchComponents();
    }, []);

    const handleSearch = () => {
        const filtered = components.filter(component => component.name.includes(search));
        setComponents(filtered);
    };

    const handleLikeComponent = async (id) => {
        try {
            await axios.post(`https://localhost/api/components/${id}/like`);
            alert('Component liked');
        } catch (error) {
            alert('Error liking component');
        }
    };

    return (
        <div>
            <h2>Components</h2>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search components" />
            <button onClick={handleSearch}>Search</button>
            <ul>
                {components.map((component) => (
                    <li key={component.id}>
                        {component.name} - Likes: {component.likes}
                        <button onClick={() => handleLikeComponent(component.id)}>Like</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Components;
